import { useState } from "react"
import styled from "styled-components"

import { Switch, Tooltip } from "@material-ui/core"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { FilterWrapper, IdWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchParadiseHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { colors } from "src/ui/colors"
import { FilterChip } from "src/ui/Filter/FilterChip"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

const LIMIT = 15

export function ParadiseUserHomes({ userId }: { userId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)
  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "homes_showDeletedHomes",
        type: "boolean",
      },
    ],
  })

  const fetchedHomes = useFetchParadiseHomes({
    filter: {
      user_id: userId,
      limit: LIMIT,
      offset,
      deleted: !!filter.homes_showDeletedHomes,
    },
    options: {
      select: (data) => {
        return {
          homes: data.homes.slice(offset, offset + LIMIT),
          total_count: data.homes.length,
        }
      },
      keepPreviousData: true,
    },
  })

  const headers = [
    <div key={"home-id"}>Home id</div>,
    <div key={"name"}>Name</div>,
    <div key={"created-at"}>Created at</div>,
    <div key={"created-by"}>Created by</div>,
    <div key={"deleted-at"}>Deleted at</div>,
  ]

  const rows =
    fetchedHomes.data?.homes.map((home) => (
      <>
        <div>
          <IdWrapper>
            <InternalLink
              to={Routes.ParadiseHome.location(home.id)}
              onClick={(e) => e.stopPropagation()}
            >
              {home.id}
            </InternalLink>
            {home.deleted && (
              <Tooltip title="Deleted">
                <InfoIcon width={12} fill={colors.systemEmergencyForeground} />
              </Tooltip>
            )}
          </IdWrapper>
        </div>
        <div>{home.name}</div>
        <div>{formatDate({ date: home.created_at, timezone: "utc" })}</div>
        <InternalLink
          to={Routes.ParadiseUser.location(home.created_by)}
          onClick={(e) => e.stopPropagation()}
        >
          {home.created_by}
        </InternalLink>
        <div>
          {home.deleted_at
            ? formatDate({ date: home.deleted_at, timezone: "utc" })
            : "-"}
        </div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Homes" />
      <FilterWrapper>
        <FilterChip
          label="Deleted"
          value={String(!!filter.homes_showDeletedHomes)}
          active={true}
        >
          <FilterChipWrapper>
            <Switch
              checked={!!filter.homes_showDeletedHomes}
              onChange={(e, checked) =>
                setFilter("homes_showDeletedHomes", checked)
              }
            />
          </FilterChipWrapper>
        </FilterChip>
      </FilterWrapper>
      <ParadiseTable
        header={headers}
        rows={rows}
        templateColumns="min-content min-content min-content min-content auto"
        onRowClick={(index) => {
          if (fetchedHomes.data?.homes) {
            navigate(
              Routes.ParadiseHome.location(
                fetchedHomes.data.homes[index].home_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedHomes.isError,
          title: fetchedHomes.error?.message,
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedHomes.data?.total_count}
      />
    </div>
  )
}

const FilterChipWrapper = styled.div`
  padding: ${spacing.S};
`
