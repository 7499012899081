import { useState } from "react"
import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import {
  ParadiseUserDevicesFilters,
  TParadiseUserDevicesFilters,
} from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserDevices/ParadiseUserDevicesFilters"
import { useFetchParadiseDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { colorsV2 } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const LIMIT = 15

export function ParadiseUserDevices({ userId }: { userId: string }) {
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState<TParadiseUserDevicesFilters>({
    description: "",
    hardwareVersion: null,
  })

  const fetchedDevices = useFetchParadiseDevices({
    filter: {
      owner_id: userId,
      limit: LIMIT,
      description: filter.description,
      offset,
      active: true,
      hardware_version: filter.hardwareVersion?.version_number ?? undefined,
    },
  })
  const devices = fetchedDevices.data?.devices ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />
      <ParadiseUserDevicesFilters filter={filter} setFilter={setFilter} />

      <OverviewWrapper>
        <Overview>
          <MText variant="nano">Total devices</MText>
          <MText variant="subtitleS">{fetchedDevices.data?.total_count}</MText>
        </Overview>
      </OverviewWrapper>
      <ParadiseDevicesTable
        devices={devices}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchedDevices.data?.total_count}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
      />
    </div>
  )
}

const OverviewWrapper = styled.div`
  display: flex;
  gap: ${spacing.S};
  margin-top: ${spacing.S};
`

const Overview = styled.div`
  padding: ${spacing.XS};
  background-color: ${colorsV2.neutral};
  border-radius: 0.5rem;
`
